import Plausible, { EventOptions } from 'plausible-tracker';

// eslint-disable-next-line new-cap
const plausible = Plausible({
  domain: 'editor.factiverse.ai',
});

plausible.enableAutoPageviews();

export const trackEvent = (eventName: string, options?: EventOptions) => {
  plausible.trackEvent(eventName, options);
};
