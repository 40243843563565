import { Stack, Typography } from '@mui/material';
import React from 'react';
import YellowButton from 'components/buttons/YellowButton';
import { useNavigate } from 'react-router-dom';

/**
 * This page gets shown for wrong routes/not found pages.
 *
 * @return {React.FC} The page component
 */
const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height="100%"
      spacing={2}
    >
      <Typography variant="h4" pb={4}>
        Sorry, we could not find the page you are looking for.
      </Typography>
      <Typography variant="h5">Please go back to the Home page.</Typography>
      <YellowButton
        onClick={() => {
          navigate('/playgroundV2', { replace: true });
        }}
      >
        Home
      </YellowButton>
    </Stack>
  );
};

export default PageNotFound;
