import CheckIcon from '@mui/icons-material/Check';
import { IconButton, Tooltip } from '@mui/material';
import CopyIcon from 'assets/img/icons/CopyIcon';
import { handleCopyToClipboard } from 'pages/PlaygroundV2Page/helpers';
import { useState } from 'react';
import { trackEvent } from 'utils/Plausible';
import { showSuccess } from 'utils/SnackbarUtils';
import { LAVENDER_STEEL } from 'utils/theme';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  successMessage?: string;
  successMessageDuration?: number;
}

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  textToCopy,
  successMessage = 'Copied to Clipboard',
  successMessageDuration = 2000,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    const copySuccess = await handleCopyToClipboard(textToCopy);

    if (copySuccess) {
      showSuccess(successMessage, successMessageDuration);
      setIsCopied(true);
      trackEvent('Copy Button Clicked');

      setTimeout(() => setIsCopied(false), successMessageDuration);
    }
  };

  return (
    <Tooltip title="Copy to clipboard" arrow>
      <IconButton onClick={handleCopyClick} sx={{ minWidth: '30px' }}>
        {isCopied ? (
          <CheckIcon
            data-testid="check-icon"
            sx={{ height: '20px', color: LAVENDER_STEEL }}
          />
        ) : (
          <CopyIcon data-testid="copy-button" sx={{ color: LAVENDER_STEEL }} />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default CopyToClipboardButton;
