import { Stack, Typography, Snackbar } from '@mui/material';
import { setSearchValue } from 'core/store/claimsV2Slice';
import { useCheckClaim } from 'pages/PlaygroundV2Page/hooks/useCheckClaim';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DARKER_GREY } from 'utils/theme';
import SuggestedButton from '../../SuggestedClaimButtons/components/SuggestedButton';
import { useGenerate } from 'pages/PlaygroundV2Page/hooks/useGenerate';
import { useAuth0 } from '@auth0/auth0-react';

interface SuggestedSearchesProps {
  suggestedSearchesData?: string[];
  title?: string;
}

const SuggestedSearches: React.FC<SuggestedSearchesProps> = ({
  suggestedSearchesData,
  title = 'Suggested searches:',
}) => {
  const checkClaim = useCheckClaim();
  const getGeneratedClaims = useGenerate();
  const { isAuthenticated } = useAuth0();

  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleClaimClick = (claim: string) => {
    dispatch(setSearchValue(claim));
    if (!isAuthenticated) {
      setSnackbarMessage('Please log in or sign up to check a claim.');
      setSnackbarOpen(true);
      return;
    }
    checkClaim(claim);
    getGeneratedClaims(claim);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const buttonStyles = {
    border: '1px solid #6C727A4D',
    hoverBackgroundColor: 'white',
    hoverBorderColor: '#6F6F6F99',
    hoverStrokeColor: DARKER_GREY,
  };

  return (
    <Stack
      mt={2}
      direction="column"
      useFlexGap
      spacing={{ xs: 3 }}
      alignItems="flex-start"
    >
      <Typography
        sx={{
          fontSize: { desktop: '20px', tablet: '20px', mobile: '18px' },
          ml: '8px',
        }}
      >
        {title}
      </Typography>

      {suggestedSearchesData?.map((claim, index) => (
        <SuggestedButton
          key={index}
          claim={claim.substring(0, claim.length - 1)}
          onClick={() => handleClaimClick(claim)}
          {...buttonStyles}
        />
      ))}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Stack>
  );
};

export default SuggestedSearches;
