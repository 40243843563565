import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Divider, Menu, Typography } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { DARKER_GREY, DIM_GRAY } from 'utils/theme';
import MenuOpenButton from './components/MenuOpenButton';
import UserMenuItem from './components/UserMenuItem';
import LogoutIcon from 'assets/img/icons/LogoutIcon';
import DevelopersIcon from 'assets/img/icons/DevelopersIcon';
import { useNavigate } from 'react-router-dom';
import { useDeployment, Deployment } from 'hooks/useDeployment';
import HelpOutlineIcon from 'assets/img/icons/HelpOutlineIcon';
import DiamondIcon from 'assets/img/icons/DiamondIcon';
import SearchIcon from 'assets/img/icons/SearchIcon';
import { resetOnLogout } from 'core/store/claimsV2Slice';
import { useDispatch } from 'react-redux';

const UserMenuStyles = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    borderRadius: '8px',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.22))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 26,
      height: 26,
      ml: -0.5,
      mr: 1.5,
    },
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 43,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

interface UserMenuProps {
  isOpen: boolean;
  setIsOpen: (newIsOpen: boolean) => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ isOpen, setIsOpen }) => {
  const { logout, user } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
    setAnchorEl(null);
    setIsOpen(false);
    dispatch(resetOnLogout());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);

  const { deployment } = useDeployment();
  const showDeveloperDashboard =
    deployment === Deployment.development || deployment === Deployment.testing;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isOpen && !anchorEl) {
      const button = buttonRef.current;
      if (button) {
        setAnchorEl(button);
      }
    }
  }, [isOpen, anchorEl]);

  return (
    <Box>
      <MenuOpenButton ref={buttonRef} onClick={handleClick} isOpen={isOpen} />
      <Menu
        anchorEl={anchorEl}
        id="user-menu"
        open={isOpen}
        onClose={handleClose}
        slotProps={{
          paper: UserMenuStyles,
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <UserMenuItem
          onClick={handleClose}
          icon={<Avatar src={user?.picture} />}
          title={
            <Typography
              color={DARKER_GREY}
              fontWeight={600}
              sx={{ opacity: 0.7 }}
            >
              {user?.name}
            </Typography>
          }
        />
        <UserMenuItem
          onClick={() => {
            navigate('/home');
            handleClose();
          }}
          icon={<SearchIcon width="26px" height="26px" />}
          title="Old Design"
        />
        <UserMenuItem
          onClick={() => {
            navigate('/faq');
            handleClose();
          }}
          icon={<HelpOutlineIcon width="26px" height="26px" />}
          title="FAQ"
        />
        <UserMenuItem
          onClick={() => {
            navigate('/pricing');
            handleClose();
          }}
          icon={<DiamondIcon width="26px" height="26px" />}
          title="Pricing"
        />
        {/* Dev Portal */}
        {showDeveloperDashboard && (
          <UserMenuItem
            onClick={() => {
              navigate('/developers');
              handleClose();
            }}
            icon={<DevelopersIcon />}
            title="Developers"
          />
        )}
        <Divider />
        <UserMenuItem
          onClick={handleLogout}
          icon={<LogoutIcon stroke={DIM_GRAY} />}
          title="Logout"
        />
      </Menu>
    </Box>
  );
};

export default UserMenu;
