import GPTIcon from 'assets/img/icons/GPTIcon';
import NewsLetterIcon from 'assets/img/icons/NewsletterIcon';
import { Box, IconButton, Tooltip } from '@mui/material';
import LinkedInIcon from 'assets/img/icons/LinkedInIcon';

export interface MenuFooterProps {
  isMobileScreen: boolean;
}

const MenuFooter = ({ isMobileScreen }: MenuFooterProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'column',
        marginBottom: '24px',
        gap: { mobile: '30px', tablet: '30px', desktop: '16px' },
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: isMobileScreen ? `1px solid #F1F1F2` : 0,
        paddingTop: isMobileScreen ? '20px' : '16px',
      }}
    >
      <Tooltip title="Try our custom GPT in ChatGPT" arrow>
        <IconButton
          onClick={() => {
            window.open('https://gpt.factiverse.ai/', '_blank');
          }}
          sx={{
            zIndex: 2200,
          }}
        >
          <GPTIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Subscribe to our Newsletter" arrow>
        <IconButton
          onClick={() => {
            window.open('https://factiverse.ghost.io/', '_blank');
          }}
          sx={{
            zIndex: 2200,
          }}
        >
          <NewsLetterIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Follow us on LinkedIn" arrow>
        <IconButton
          onClick={() => {
            window.open(
              'https://www.linkedin.com/company/factiverse',
              '_blank'
            );
          }}
          sx={{
            zIndex: 2200,
          }}
        >
          <LinkedInIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default MenuFooter;
