import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  filteredClaimResults,
  resetOnLogout,
  setClaimLoading,
  setClaimResults,
  setIsNoDataFound,
  setSearchValue,
} from 'core/store/claimsV2Slice';
import SuggestedButton from '../SuggestedClaimButtons/components/SuggestedButton';
import { exampleTestClaims } from 'data/exampleTestClaims';
import { claimResults } from '../../../data/testClaimData';
import { useAppSelector } from 'core/store/hooks';
import { trackEvent } from 'utils/Plausible';

const CheckExampleClaims: React.FC = () => {
  const dispatch = useDispatch();
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);

  const handleClaimClick = (claim: string) => {
    dispatch(resetOnLogout());
    dispatch(setSearchValue(claim));
    dispatch(setClaimLoading(true));
    dispatch(
      filteredClaimResults({
        claim: '',
      })
    );
    dispatch(setIsNoDataFound(false));

    const matchingClaim = claimResults.find((c) => c.claim === claim);

    setTimeout(() => {
      if (matchingClaim) {
        dispatch(setClaimResults(matchingClaim));
        dispatch(filteredClaimResults(matchingClaim));
        trackEvent('Example Claim Clicked');
      } else {
        dispatch(setIsNoDataFound(true));
      }
      dispatch(setClaimLoading(false));
    }, 3000);
  };

  useEffect(() => {
    if (claimLoading && searchValue) {
      const matchedClaim = claimResults.find((c) => c.claim === searchValue);

      setTimeout(() => {
        if (matchedClaim) {
          dispatch(setClaimResults(matchedClaim));
          dispatch(filteredClaimResults(matchedClaim));
        } else {
          dispatch(setIsNoDataFound(true));
        }
        dispatch(setClaimLoading(false));
      }, 3000);
    }
  }, [claimLoading, dispatch, searchValue]);

  return (
    <Stack
      mt={5}
      direction="row"
      flexWrap="wrap"
      useFlexGap
      spacing={{ xs: 3 }}
      justifyContent="flex-start"
    >
      {exampleTestClaims.map((claim, index) => (
        <SuggestedButton
          key={index}
          claim={claim}
          onClick={() => handleClaimClick(claim)}
        />
      ))}
    </Stack>
  );
};

export default CheckExampleClaims;
