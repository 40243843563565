import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import Claim from './Claim';
import Header from './Header';
import useScrollToClaims from './hooks/useScrollToClaims';
import NavBar from './NavBar';
import NoSourcesFoundComponent from './NoSourcesFoundComponent';
import SearchField from './SearchField';
import SuggestedClaimButtons from './SuggestedClaimButtons';
import Summary from './Summary';
import ErrorComponent from './ErrorDescriptionComponent';
import { Deployment, useDeployment } from 'hooks/useDeployment';
import RelatedClaims from './RelatedClaims/RelatedClaims';
import { useAuth0 } from '@auth0/auth0-react';
import CheckExampleClaims from './CheckExampleClaims/CheckExampleClaims';
import { useState } from 'react';
import AppInDevelopmentNotice from './AppInDevelopmentNotice/AppInDevelopmentNotice';

const bodyWidth = 1000;

const PlaygroundV2Page = () => {
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const hasError = useAppSelector((state) => state.claimsV2.error);
  const suggestedSearches = useAppSelector(
    (state) => state.claimsV2.generatedClaims
  );
  const claimHasEvidence = claim.evidence && claim.evidence?.length > 0;
  const isNoDataFound = useAppSelector((state) => state.claimsV2.isNoDataFound);
  const { scrollTargetRef } = useScrollToClaims();
  const { isAuthenticated } = useAuth0();
  const [navbarWidth, setNavbarWidth] = useState(145);

  const handleNavbarToggle = (isExpanded: boolean) => {
    setNavbarWidth(isExpanded ? 240 : 145);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('desktop'));
  const { deployment } = useDeployment();
  const showRelatedClaims =
    deployment === Deployment.development || deployment === Deployment.testing;
  const hasSuggestedSearches =
    suggestedSearches && suggestedSearches.length > 0;
  const shouldShowRelatedClaims =
    showRelatedClaims && !claimLoading && hasSuggestedSearches;

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: 'auto',
        minHeight: '100vh',
        flexDirection: { mobile: 'column', tablet: 'column', desktop: 'row' },
      }}
    >
      <Box
        sx={{
          width: {
            mobile: '100%',
            tablet: '100%',
            desktop: `${navbarWidth}px`,
          },
          top: 0,
          zIndex: 1000,
          display: 'flex',
          justifyContent: {
            mobile: 'center',
            tablet: 'center',
            desktop: 'flex-start',
          },
          transition: 'width 0.3s ease',
        }}
      >
        <NavBar handleNavbarToggle={handleNavbarToggle} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: {
              mobile: '100%',
              tablet: '100%',
              desktop: `${bodyWidth}px`,
            },
            boxSizing: 'border-box',
            padding: {
              mobile: '16px',
              tablet: '16px 24px 24px',
              desktop: '24px',
            },
          }}
        >
          {!isSmallScreen && <Header />}
          <SearchField />
          {!(claimLoading || claimHasEvidence) && <AppInDevelopmentNotice />}
          {/* show when there is no evidence was found */}
          {!claimLoading && isNoDataFound && <NoSourcesFoundComponent />}
          {/* show when there is an encounter of error */}
          {!claimLoading && hasError && <ErrorComponent />}

          {/* show when there is no data and not loading */}
          {!claimLoading && !claimHasEvidence && isAuthenticated && (
            <SuggestedClaimButtons />
          )}
          {/* show when there is data and loading */}
          {(claimLoading || claimHasEvidence) && (
            <Summary isLoading={claimLoading} claim={claim} />
          )}
          {/* show when there is data and not loading */}
          {!claimLoading && claimHasEvidence && (
            <Box ref={scrollTargetRef}>
              <Claim />
            </Box>
          )}
          {/* show when user is not logged in and no test claim is being searched */}
          {!isAuthenticated && !claimHasEvidence && <CheckExampleClaims />}
          {/* show in testing and development when there is related claims available */}
          {shouldShowRelatedClaims && <RelatedClaims />}
        </Box>
      </Box>
    </Box>
  );
};

export default PlaygroundV2Page;
