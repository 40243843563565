import { Box, Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { useMemo, useState } from 'react';
import useSourceTypes from '../hooks/useSourceTypes';
import ClaimEvidence from './components/ClaimEvidence';
import ClaimSummary from './components/ClaimSummary';

const Claim = () => {
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const claim = useAppSelector((state) => state.claimsV2.claim);
  const selectedSortingAccordion = useAppSelector(
    (state) => state.claimsV2.selectedSortingAccordion
  );
  const favoriteDomains = useAppSelector(
    (state) => state.claimsV2.favoriteDomains
  );

  const { groupedSourceTypes } = useSourceTypes(filteredClaim.evidence ?? []);
  const [expandedEvidence, setExpandedEvidence] = useState<string | null>(null);

  const sortedOrFilteredSources = useMemo(() => {
    const sources = groupedSourceTypes[selectedSortingAccordion];

    return sources.slice().sort((sourceA, sourceB) => {
      const isSourceAFavorite = (favoriteDomains || []).includes(
        sourceA.domain
      );
      const isSourceBFavorite = (favoriteDomains || []).includes(
        sourceB.domain
      );

      return isSourceAFavorite === isSourceBFavorite
        ? 0
        : isSourceAFavorite
        ? -1
        : 1;
    });
  }, [groupedSourceTypes, selectedSortingAccordion, favoriteDomains]);

  return (
    <Box>
      {filteredClaim && (
        <ClaimSummary filteredClaim={filteredClaim} claim={claim} />
      )}
      <Stack spacing={2} pb={2}>
        {sortedOrFilteredSources?.map((source, index) => (
          <ClaimEvidence
            index={index}
            evidence={source}
            key={index}
            claim={filteredClaim}
            expandedEvidence={expandedEvidence}
            setExpandedEvidence={setExpandedEvidence}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default Claim;
