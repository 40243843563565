import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import ExpandLessIcon from 'assets/img/icons/ExpandLessIcon';
import ExpandMoreIcon from 'assets/img/icons/ExpandMoreIcon';
import { setSelectedSortingAccordion } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import useScrollToClaims from 'pages/PlaygroundV2Page/hooks/useScrollToClaims';
import useSourceTypes from 'pages/PlaygroundV2Page/hooks/useSourceTypes';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  BRIGHT_BLUE,
  CLOUD_MIST,
  DARK_GREY,
  LIGHT_GREEN,
  LIGHT_PINK,
  LIGHT_YELLOW,
} from 'utils/theme';
import AccordionItem from './AccordionItem';

const SortingAccordion = ({ evidence }: { evidence: Source[] }) => {
  const dispatch = useDispatch();
  const { groupedSourceTypes } = useSourceTypes(evidence);
  const selectedSortingAccordion = useAppSelector(
    (state) => state.claimsV2.selectedSortingAccordion
  );
  const { handleScrollToClaim } = useScrollToClaims();

  const accordionItems = [
    {
      name: SourceTypes.ALL,
      itemColor: CLOUD_MIST,
    },
    {
      name: SourceTypes.SUPPORTING,
      itemColor: LIGHT_GREEN,
    },
    {
      name: SourceTypes.MIXED,
      itemColor: LIGHT_YELLOW,
    },
    {
      name: SourceTypes.DISPUTING,
      itemColor: LIGHT_PINK,
    },
  ];
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const itemRefs = useRef({});

  useEffect(() => {
    if (
      isMobile &&
      selectedSortingAccordion &&
      itemRefs.current[selectedSortingAccordion]
    ) {
      itemRefs.current[selectedSortingAccordion].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [selectedSortingAccordion, isMobile]);

  return (
    <Box overflow="auto" whiteSpace="nowrap" maxWidth="100%">
      <Stack direction="row" display="flex">
        {accordionItems.map(({ name, itemColor }, key) => {
          const items = groupedSourceTypes[name];

          if (!items || items.length === 0) {
            return null;
          }

          return (
            <Box key={key} ref={(el) => (itemRefs.current[name] = el)}>
              <AccordionItem
                title={name}
                count={items?.length}
                itemColor={itemColor}
                onClick={() => {
                  dispatch(setSelectedSortingAccordion(name));
                  handleScrollToClaim();
                }}
                titleColor={
                  selectedSortingAccordion === name ? BRIGHT_BLUE : DARK_GREY
                }
                endIcon={
                  selectedSortingAccordion === name ? (
                    <ExpandLessIcon stroke={BRIGHT_BLUE} />
                  ) : (
                    <ExpandMoreIcon />
                  )
                }
                selected={selectedSortingAccordion === name}
              />
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default SortingAccordion;
