import React, { useState } from 'react';
import {
  Box,
  Tooltip,
  useMediaQuery,
  ClickAwayListener,
  useTheme,
} from '@mui/material';

const ClickableTooltip = ({
  tooltipText,
  children,
}: {
  tooltipText: React.ReactNode;
  children: React.ReactNode;
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('desktop'));
  const handleTooltipOpen = () => {
    if (isMobileOrTablet) setOpen(true);
  };

  const handleTooltipClose = () => {
    if (isMobileOrTablet) setOpen(false);
  };

  const tooltipProps = isMobileOrTablet
    ? {
        open,
        disableHoverListener: true,
        disableFocusListener: true,
        disableTouchListener: true,
      }
    : {};

  return (
    <Box component="span">
      {isMobileOrTablet ? (
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip title={tooltipText} arrow {...tooltipProps}>
            <Box
              component="span"
              onClick={handleTooltipOpen}
              sx={{ display: 'inline-flex' }}
            >
              {children}
            </Box>
          </Tooltip>
        </ClickAwayListener>
      ) : (
        <Tooltip title={tooltipText} arrow>
          <Box component="span" sx={{ display: 'inline-flex' }}>
            {children}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default ClickableTooltip;
