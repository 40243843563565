import Card from 'pages/PlaygroundV2Page/components/Card';
import {
  ClaimEvidenceBody,
  ClaimEvidenceFooter,
  ClaimEvidenceHeader,
} from './ClaimEvidenceComponents';

const ClaimEvidence = ({
  evidence,
  claim,
  index,
  expandedEvidence,
  setExpandedEvidence,
}: {
  evidence: Source;
  index: number;
  claim: Claim;
  expandedEvidence: string | null;
  setExpandedEvidence: (name: string | null) => void;
}) => {
  return (
    <Card
      sx={{
        padding: {
          mobile: '20px 16px 16px',
          tablet: '25px 30px',
          desktop: '25px 30px',
        },
      }}
      spacing={2}
    >
      <ClaimEvidenceHeader index={index} evidence={evidence} />
      <ClaimEvidenceBody
        evidence={evidence}
        claim={claim}
        expandedEvidence={expandedEvidence}
        setExpandedEvidence={setExpandedEvidence}
      />
      <ClaimEvidenceFooter evidence={evidence} />
    </Card>
  );
};

export default ClaimEvidence;
