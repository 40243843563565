import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PlaygroundV2Page from './PlaygroundV2Page';

const LandingPage: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    isAuthenticated && navigate('/playgroundV2');
  }, [isAuthenticated, navigate]);

  return <PlaygroundV2Page />;
};

export default LandingPage;
