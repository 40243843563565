import { Button, Skeleton, Stack, Typography } from '@mui/material';
import Sum from 'components/Sum';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import useScrollToClaims from 'pages/PlaygroundV2Page/hooks/useScrollToClaims';
import { DonutChartItem } from '.';
import { useDispatch } from 'react-redux';
import { setSelectedSortingAccordion } from 'core/store/claimsV2Slice';
import TooltipIcon from 'pages/PlaygroundV2Page/components/TooltipIcon';

const tootlipTitle = (isMixed: boolean, value: number, title: string) => {
  if (isMixed) {
    return `We found ${value} sources which have a mixed position on this claim.`;
  } else {
    return `We found ${value} sources which are generally ${title} this claim.`;
  }
};

const SkeletonLegendItem = () => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    gap={2}
    mb={1}
  >
    <Skeleton variant="rounded" height="26px" width="26px" />
    <Skeleton variant="text" height="13px" width="150px" />
  </Stack>
);

interface DonutChartLegendProps {
  data: DonutChartItem[];
  palette: string[];
  isLoading: boolean;
}

const DonutChartLegend = ({
  data,
  palette,
  isLoading,
}: DonutChartLegendProps) => {
  const { handleScrollToClaim } = useScrollToClaims();
  const dispatch = useDispatch();

  return (
    <Stack gap={1} alignItems="flex-start">
      {data.map(({ title, value }, index) => {
        const isMixed = title === SourceTypes.MIXED;

        return value === 0 && !isLoading ? null : (
          <Stack direction="row" gap={1} key={index}>
            {isLoading ? (
              <SkeletonLegendItem />
            ) : (
              <Stack direction="row" alignItems="center">
                <Sum
                  color={palette[index % palette.length]}
                  count={value}
                  isSum={true}
                />
                <Button
                  variant="text"
                  onClick={() => {
                    handleScrollToClaim();
                    dispatch(setSelectedSortingAccordion(title));
                  }}
                  sx={{
                    textTransform: 'capitalize',
                    textDecoration: 'underline',
                    color: 'black',
                    justifyContent: 'flex-start',
                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                >
                  <Typography variant="subtitle1">{title}</Typography>
                </Button>
                <Typography>links found</Typography>
                <TooltipIcon
                  tooltip={tootlipTitle(isMixed, value, title)}
                  sx={{
                    paddingLeft: '8px',
                    marginTop: '6px',
                  }}
                />
              </Stack>
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default DonutChartLegend;
