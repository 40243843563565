import CloudIcon from 'assets/img/icons/CloudIcon';
import ActionMenuList from '../components/ActionMenuList';
import { useExportActions } from './useExportActions';
import { trackEvent } from 'utils/Plausible';

const Export = () => {
  const { handleCSVExport } = useExportActions();

  const handleExportClick = () => {
    handleCSVExport();
    trackEvent('Export Button Clicked');
  };

  return (
    <ActionMenuList
      items={[
        {
          icon: <CloudIcon />,
          name: '.CSV',
          onClick: handleExportClick,
        },
      ]}
      title="Export"
      icon={<CloudIcon fontSize="small" />}
    />
  );
};

export default Export;
