import SvgIcon from '@mui/material/SvgIcon';

const NewsLetterIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '36px',
        height: props.height ?? '36px',
        fill: props.fill ?? 'none',
      }}
    >
      <path
        d="M10.355 17.1611C11.1438 17.923 18.0382 23.2338 18.0382 23.2338L25.8696 17.1611"
        stroke="#6C727A"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M27.2057 14.981C27.3175 15.076 27.382 15.2153 27.382 15.362V25.9433C27.382 26.2195 27.1581 26.4433 26.882 26.4433H9.146C8.86985 26.4433 8.646 26.2195 8.646 25.9433V15.3628C8.646 15.2156 8.71086 15.0759 8.82329 14.9809L10.967 13.1696C11.0794 13.0746 11.1443 12.9349 11.1443 12.7877V9.55247C11.1443 9.50926 11.1789 9.47461 11.2221 9.47461H24.3179C24.5941 9.47461 24.8179 9.69847 24.8179 9.97461V12.7215C24.8179 12.8683 24.8824 13.0076 24.9942 13.1026L27.2057 14.981Z"
        stroke="#6C727A"
        strokeMiterlimit="10"
      />
      <path
        d="M24.8169 17.9762L24.8169 11.1475"
        stroke="#6C727A"
        strokeMiterlimit="10"
      />
      <path
        d="M11.144 17.7468L11.144 10.9131"
        stroke="#6C727A"
        strokeMiterlimit="10"
      />
      <path
        d="M14.6934 12.6982H21.3329"
        stroke="#6C727A"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.6934 15.5254H21.3329"
        stroke="#6C727A"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.6934 18.3535H21.3329"
        stroke="#6C727A"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default NewsLetterIcon;
