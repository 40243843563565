import React, { useCallback } from 'react';
import { Stack, IconButton } from '@mui/material';
import ThumbsUpIcon from 'assets/img/icons/ThumbsUpIcon';
import ThumbsDownIcon from 'assets/img/icons/ThumbsDownIcon';
import ThumbsDownFeedbackActionMenuList, {
  ActionMenuListItem,
} from './ThumbsDownFeedbackActionMenuList';
import {
  feedbackSourceRelevanceRequest,
  feedbackSourceSupportRequest,
} from 'utils/API/feedback';
import { CLOUD_MIST, LIGHT_VIOLET } from 'utils/theme';
import { useAuth0 } from '@auth0/auth0-react';
import useSettings from 'hooks/useSettings';
import { useEvidenceLabel } from 'pages/PlaygroundV2Page/hooks/useEvidendeLabel';
import useUpdateUserFeedback from './hooks/useUpdateUserFeedback';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import { trackEvent } from 'utils/Plausible';

interface FeedbackActionProps {
  claim: Claim;
  source: Source;
}

const FeedbackAction = ({ claim, source }: FeedbackActionProps) => {
  const { user } = useAuth0();
  const { allowTracking } = useSettings();
  const { getLabel } = useEvidenceLabel();

  const {
    updateEvidenceWithRelevance,
    updateEvidenceWithSupport,
    updateEvidenceWithUserFeedback,
  } = useUpdateUserFeedback();

  const getStanceText = () => {
    const stance = getLabel(source);
    switch (stance) {
      case SourceTypes.SUPPORTING:
        return 'supports';
      case SourceTypes.DISPUTING:
        return 'disputes';
      default:
        return 'supports';
    }
  };
  const stance = getStanceText();

  const onFeedbackSourceRelevance = useCallback(
    (userAgrees: boolean) => {
      feedbackSourceRelevanceRequest(
        claim.claim,
        user?.email ?? '',
        userAgrees,
        claim?.language ?? 'en',
        source,
        allowTracking
      );
    },
    [allowTracking, user?.email, claim, source]
  );

  const onFeedbackSourceSupport = useCallback(
    (userAgrees: boolean) => {
      feedbackSourceSupportRequest(
        claim.claim,
        user?.email ?? '',
        userAgrees,
        claim?.language ?? 'en',
        source,
        allowTracking
      );
    },
    [allowTracking, user?.email, claim, source]
  );

  const menuItems: ActionMenuListItem[] = [
    {
      name: 'Source is relevant',
      icons: [
        {
          icon: <ThumbsUpIcon />,
          onClick: () => {
            onFeedbackSourceRelevance(true);
            updateEvidenceWithRelevance(source._id, true);
            trackEvent('Thumbs Up Source Relevance Clicked');
          },
        },
        {
          icon: <ThumbsDownIcon />,
          onClick: () => {
            onFeedbackSourceRelevance(false);
            updateEvidenceWithRelevance(source._id, false);
            trackEvent('Thumbs Down Source Relevance Clicked');
          },
        },
      ],
    },
    {
      name: `Source ${stance} claim`,
      icons: [
        {
          icon: <ThumbsUpIcon />,
          onClick: () => {
            onFeedbackSourceSupport(true);
            updateEvidenceWithSupport(source._id, true);
            trackEvent(`Thumbs Up Source ${stance} Clicked`);
          },
        },
        {
          icon: <ThumbsDownIcon />,
          onClick: () => {
            onFeedbackSourceSupport(false);
            updateEvidenceWithSupport(source._id, false);
            trackEvent(`Thumbs Down Source ${stance} Clicked`);
          },
        },
      ],
    },
  ];

  const handleThumbsUpFeedbackClick = () => {
    onFeedbackSourceSupport(true);
    onFeedbackSourceRelevance(true);
    updateEvidenceWithUserFeedback(source._id, true, true);
    trackEvent('Thumbs Up Source Relevance Clicked');
    trackEvent(`Thumbs Up Source ${stance} Clicked`);
  };

  const ThumbsUpFeedbackButton = () => {
    const isFill = source.userAgreesRelevance && source.userAgreesSupport;
    return (
      <Stack>
        <IconButton
          aria-label="thumbsup"
          onClick={handleThumbsUpFeedbackClick}
          sx={{
            '&:hover': {
              background: CLOUD_MIST,
            },
          }}
        >
          <ThumbsUpIcon fill={isFill ? LIGHT_VIOLET : 'none'} />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Stack direction="row">
      <ThumbsUpFeedbackButton />

      <ThumbsDownFeedbackActionMenuList
        title="I agree (yes or no):"
        icon={<ThumbsDownIcon />}
        items={menuItems}
        source={source}
      />
    </Stack>
  );
};

export default FeedbackAction;
