import { Stack, Typography } from '@mui/material';
import ClickableTooltip from 'pages/PlaygroundV2Page/components/ClickableTooltip';
import { CLOUD_MIST, LAVENDER_STEEL } from 'utils/theme';

const SummaryHeader = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={2}
      sx={{
        pr: { mobile: 0, tablet: 0, desktop: '30px' },
        pl: { mobile: 0, tablet: '24px', desktop: '24px' },
      }}
    >
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 500,
        }}
      >
        Summary
      </Typography>
      <ClickableTooltip tooltipText="We summarize the main points of the found sources with the help of generative AI.">
        <Typography
          sx={{
            width: '49px',
            backgroundColor: CLOUD_MIST,
            color: LAVENDER_STEEL,
            fontSize: '12px',
            borderRadius: '4px',
            textAlign: 'center',
          }}
        >
          Beta
        </Typography>
      </ClickableTooltip>
    </Stack>
  );
};

export default SummaryHeader;
