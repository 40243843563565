import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { containsInteger } from 'pages/PlaygroundV2Page/helpers';
import Loading from '../../Loading';
import TooltipIcon from 'pages/PlaygroundV2Page/components/TooltipIcon';
import { DARKER_GREY } from 'utils/theme';

const LeftSide = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);

  const searchContainInteger = containsInteger(searchValue);

  const TooltipText =
    'Always double check references when using numbers in your claims, as they are the most difficult to verify.';

  if (!claimLoading && !searchContainInteger) {
    return null; // Render nothing
  }

  return (
    <Box data-testid="searchFieldFooterLeft">
      {claimLoading ? (
        <Loading
          labels={[
            'Detecting language',
            'Finding factual sentences',
            'Searching on Google',
            'Searching on Bing',
            'Searching on Semantic Scholar',
            'Browsing fact-checks',
            'Ranking results',
          ]}
        />
      ) : (
        <Box>
          {searchContainInteger && (
            <Box display="flex" flexDirection="row" gap="8px">
              <TooltipIcon tooltip={TooltipText} sx={{ marginTop: '4px' }} />
              <Typography fontSize="14px" color={DARKER_GREY}>
                Numbers in your claims
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LeftSide;
