import SvgIcon from '@mui/material/SvgIcon';

const ArrowRightIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 7 10"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '7px',
        height: props.height ?? '10px',
        fill: props.fill ?? 'none',
      }}
    >
      <path
        d="M1.49981 1.22638L5.38867 5.11523L1.49981 9.00409"
        stroke="#545454"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
