import SvgIcon from '@mui/material/SvgIcon';

const LinkedInIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '36px',
        height: props.height ?? '36px',
        fill: props.fill ?? 'none',
      }}
    >
      <g clipPath="url(#clip0_5292_7762)">
        <path
          d="M23.3298 9.95898H9.69541C9.04229 9.95898 8.51416 10.4746 8.51416 11.1121V24.8027C8.51416 25.4402 9.04229 25.959 9.69541 25.959H23.3298C23.9829 25.959 24.5142 25.4402 24.5142 24.8059V11.1121C24.5142 10.4746 23.9829 9.95898 23.3298 9.95898ZM13.261 23.5934H10.886V15.9559H13.261V23.5934ZM12.0735 14.9152C11.311 14.9152 10.6954 14.2996 10.6954 13.5402C10.6954 12.7809 11.311 12.1652 12.0735 12.1652C12.8329 12.1652 13.4485 12.7809 13.4485 13.5402C13.4485 14.2965 12.8329 14.9152 12.0735 14.9152ZM22.1485 23.5934H19.7767V19.8809C19.7767 18.9965 19.761 17.8559 18.5423 17.8559C17.3079 17.8559 17.1204 18.8215 17.1204 19.8184V23.5934H14.7517V15.9559H17.0267V16.9996H17.0579C17.3735 16.3996 18.1485 15.7652 19.3017 15.7652C21.7048 15.7652 22.1485 17.3465 22.1485 19.4027V23.5934Z"
          fill="#6C727A"
        />
      </g>
      <defs>
        <clipPath id="clip0_5292_7762">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8.51416 9.95898)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default LinkedInIcon;
