import SvgIcon from '@mui/material/SvgIcon';

const ArrowRightIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 7 10"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '7px',
        height: props.height ?? '10px',
        fill: props.fill ?? 'none',
      }}
    >
      <path
        d="M5.16925 9.13156L1.37598 5.14941L5.35813 1.35614"
        stroke="#545454"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default ArrowRightIcon;
