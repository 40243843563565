import { setGeneratedClaims } from 'core/store/claimsV2Slice';
import { useDispatch } from 'react-redux';
import { generateRelatedClaimsRequest } from 'utils/API/generateRelatedClaims';
import { promptText } from '../helpers';
import { useAppSelector } from 'core/store/hooks';

export const useGenerate = () => {
  const dispatch = useDispatch();
  const storedPrompt = useAppSelector((state) => state.claimsV2.searchPrompt);

  const getGeneratedClaims = (claimText: string) => {
    if (claimText.length === 0) return;
    const searchPrompt = storedPrompt || promptText;
    generateRelatedClaimsRequest(true, claimText, searchPrompt)
      .then((response) => {
        if (response.data.sentences.length) {
          dispatch(setGeneratedClaims(response.data.sentences));
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return getGeneratedClaims;
};
