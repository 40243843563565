import { Grid, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import Card from '../components/Card';

const AppInDevelopmentNotice: React.FC = () => {
  const textStyles = {
    fontSize: { desktop: '20px', tablet: '20px', mobile: '18px' },
    color: 'black',
  };
  const linkStyles = { color: 'black', textDecorationColor: 'black' };

  const ReusableLink: React.FC<{ href: string; text: string }> = ({
    href,
    text,
  }) => (
    <Link href={href} target="_blank" rel="noopener noreferrer" sx={linkStyles}>
      {text}
    </Link>
  );

  const Header: React.FC = () => (
    <Stack width="100%">
      <Typography fontWeight="500" sx={{ fontSize: textStyles.fontSize }}>
        Welcome to Factiverse!
      </Typography>
    </Stack>
  );

  const Description: React.FC = () => (
    <Stack
      direction="column"
      alignItems="flex-start"
      mb={2}
      sx={{
        mr: { desktop: 6, tablet: 6, mobile: 1 },
        mt: { desktop: 3, tablet: 3, mobile: 2 },
      }}
      width="100%"
      gap={2}
    >
      <Typography sx={textStyles}>
        Welcome to the updated Editor by Factiverse. This version is being
        prepared for our upcoming launch, and issues will occur. Thanks for
        bearing with us - you can access the old design{' '}
        <ReusableLink href="/home" text="here." />
      </Typography>
      <Typography sx={textStyles}>
        Want to know once we go live with all the updates? Sign up{' '}
        <ReusableLink href="https://factiverse.ghost.io/" text="here!" />
      </Typography>
    </Stack>
  );

  return (
    <Card
      sx={{
        paddingX: { mobile: '16px', tablet: '30px', desktop: '30px' },
        paddingY: { mobile: '16px', tablet: '30px', desktop: '30px' },
      }}
    >
      <Grid container>
        <Header />
        <Description />
      </Grid>
    </Card>
  );
};

export default AppInDevelopmentNotice;
