import SvgIcon from '@mui/material/SvgIcon';

const DotIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 11 11"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '11px',
        height: props.height ?? '11px',
        fill: props.fill ?? 'none',
      }}
    >
      <rect
        width="6"
        height="6"
        transform="matrix(0.705207 -0.709002 -0.709002 -0.705207 5.20679 10.7212)"
        fill="#8289A766"
      />
    </SvgIcon>
  );
};

export default DotIcon;
