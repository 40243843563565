import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import DotIcon from 'assets/img/icons/DotIcon';

const NoSummaryFound = () => (
  <Typography
    sx={{
      fontSize: '18px',
      py: 2,
      pl: '30px',
    }}
  >
    Summary isn’t available for this content at the moment. Please review the
    content down bellow for detailed information.
  </Typography>
);

interface SummaryTextProps {
  summary: string[];
}

const SummaryText: React.FC<SummaryTextProps> = ({ summary }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <Box>
      {summary && summary.length !== 0 ? (
        summary.map((sum, key) => (
          <Stack
            alignItems="flex-start"
            direction="row"
            mb={2}
            mt={2}
            key={key}
          >
            {!isMobileScreen && (
              <Box paddingX="6px" pt="4px">
                <DotIcon />
              </Box>
            )}
            <Typography fontSize="18px">{sum}</Typography>
          </Stack>
        ))
      ) : (
        <NoSummaryFound />
      )}
    </Box>
  );
};

export default SummaryText;
