import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button, MobileStepper, Stack, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ISMOBILE_BREAKPOINT, theme } from 'utils/theme';

const Stepper = ({
  steps,
  activeStep,
  handleNext,
  handleBack,
  submitButton,
  disabledSkip,
}: {
  steps: number;
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  submitButton: JSX.Element;
  disabledSkip: boolean;
}) => {
  const isMobile = useMediaQuery(ISMOBILE_BREAKPOINT);
  const navigate = useNavigate();
  return (
    <MobileStepper
      steps={steps}
      position="static"
      activeStep={activeStep}
      sx={{ background: 'transparent', px: 0 }}
      nextButton={
        <Stack direction="row">
          <Button
            onClick={() => {
              navigate('/playgroundV2');
            }}
            disabled={disabledSkip}
            variant="text"
            sx={{
              textTransform: 'capitalize',
              color: 'GrayText',
              mr: isMobile ? 0 : 2,
            }}
          >
            Skip
          </Button>
          {activeStep === steps - 1 ? (
            submitButton
          ) : (
            <Button size="small" onClick={handleNext}>
              <Stack flexDirection="row" alignItems="center">
                Next
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Stack>
            </Button>
          )}
        </Stack>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
          Back
        </Button>
      }
    />
  );
};

export default Stepper;
