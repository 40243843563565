import { Stack } from '@mui/material';
import CalendarIcon from 'assets/img/filter/CalendarIcon';
import GlobeIcon from 'assets/img/filter/GlobeIcon';
import { useState } from 'react';
import AccordionComponent from './components/AccordionComponent';
import DateFilter from './components/DateFilter';
import PublishedSinceFilter from './components/PublishedSinceFilter';
import SearchEngineFilter from './components/SearchEngineFilter';

const PopoverBody = () => {
  const [expanded, setExpanded] = useState<string | null>('searchEngines');

  const handleChange = (selectedFilter: string) => {
    setExpanded((prev) => (prev === selectedFilter ? null : selectedFilter));
  };

  return (
    <Stack>
      <AccordionComponent
        title="Select search engines"
        icon={<GlobeIcon />}
        expanded={expanded === 'searchEngines'}
        onChange={() => handleChange('searchEngines')}
      >
        <SearchEngineFilter />
      </AccordionComponent>

      <AccordionComponent
        title="Sort by date"
        icon={<CalendarIcon />}
        expanded={expanded === 'sortByDate'}
        onChange={() => handleChange('sortByDate')}
      >
        <DateFilter />
      </AccordionComponent>

      <AccordionComponent
        title="Published since"
        icon={<CalendarIcon />}
        expanded={expanded === 'publishedSince'}
        onChange={() => handleChange('publishedSince')}
      >
        <PublishedSinceFilter />
      </AccordionComponent>
    </Stack>
  );
};

export default PopoverBody;
