import React, { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import ReadAloudIcon from 'assets/img/icons/ReadAloudIcon';
import { LAVENDER_STEEL } from 'utils/theme';
import { detectLanguage, getLocaleCode } from 'utils/languages';
import { trackEvent } from 'utils/Plausible';

interface ReadAloudButtonProps {
  text: string;
}

const ReadAloudButton: React.FC<ReadAloudButtonProps> = ({ text }) => {
  const [isReading, setIsReading] = useState(false);

  const handleReadAloud = () => {
    if (isReading) {
      window.speechSynthesis.cancel();
      setIsReading(false);
    } else {
      const utterance = new SpeechSynthesisUtterance(text);
      const detectedLang = detectLanguage(text);
      utterance.lang = getLocaleCode(detectedLang);

      // Get available voices and find matching voice for the language
      const voices = window.speechSynthesis.getVoices();
      const matchingVoice = voices.find((voice) =>
        voice.lang.toLowerCase().startsWith(detectedLang.toLowerCase())
      );

      if (matchingVoice) {
        utterance.voice = matchingVoice;
      }

      utterance.rate = 1;
      utterance.pitch = 1;

      trackEvent('Read Aloud Button Clicked');

      utterance.onend = () => {
        setIsReading(false);
      };

      window.speechSynthesis.cancel();
      window.speechSynthesis.speak(utterance);
      setIsReading(true);
    }
  };

  return (
    <Tooltip title={isReading ? 'Stop' : 'Read Aloud'} arrow>
      <IconButton onClick={handleReadAloud} sx={{ marginLeft: '-8px' }}>
        {isReading ? (
          <StopIcon sx={{ height: '20px', color: LAVENDER_STEEL }} />
        ) : (
          <ReadAloudIcon
            data-testid="VolumeUpIcon"
            sx={{ color: LAVENDER_STEEL }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ReadAloudButton;
