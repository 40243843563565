import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from 'assets/img/icons/ExpandMoreIcon';
import { DARKER_GREY, SOFT_BLUE } from 'utils/theme';

interface AccordionComponentProps {
  title: string;
  icon: JSX.Element;
  children: JSX.Element;
  expanded: boolean;
  onChange: () => void;
}

const AccordionComponent = ({
  title,
  icon,
  children,
  expanded,
  onChange,
}: AccordionComponentProps) => {
  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      disableGutters
      sx={{ boxShadow: 'none' }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon data-testid="ExpandMoreIcon" />}
        sx={{
          background: SOFT_BLUE,
          width: '439px',
          height: '40px',
          px: 4,
        }}
      >
        {icon}
        <Typography sx={{ left: 14, position: 'relative', color: DARKER_GREY }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 9, py: 1 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
