import { IconButton } from '@mui/material';
import HelpOutlineIcon from 'assets/img/icons/HelpOutlineIcon';
import ClickableTooltip from './ClickableTooltip';

const TooltipIcon = ({
  tooltip,
  sx,
}: {
  tooltip: React.ReactNode;
  sx?: object;
}) => {
  return (
    <ClickableTooltip tooltipText={tooltip}>
      <IconButton sx={{ width: '18px', height: '18px' }}>
        <HelpOutlineIcon
          sx={{ color: '#6C727A80', width: '18px', height: '18px', ...sx }}
        />
      </IconButton>
    </ClickableTooltip>
  );
};

export default TooltipIcon;
