import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const PostPaymentPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false); // Initialize open state as false
  const isPaidUser = searchParams.get('payment') === 'true';
  const isNotPaidUser = searchParams.get('payment') === 'false';

  const { logout } = useAuth0();

  useEffect(() => {
    if (isPaidUser) {
      setOpen(true);
    } else if (isNotPaidUser) {
      setOpen(true);
    } else {
      navigate('/playgroundV2');
    }
    // Set open state to true after component mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotPaidUser, isPaidUser, searchParams]);

  const handleClose = () => {
    setOpen(false);
    logout();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h4">Payment Status</Typography>
      </DialogTitle>
      <DialogContent>
        {isPaidUser && (
          <Typography>
            Payment successful, thank you for your purchase!{' '}
            <b>Please log in again</b> to update your subscription status.
          </Typography>
        )}
        {isNotPaidUser && (
          <Typography>
            Your payment was unsuccessful, please try again or contact us at{' '}
            <Link href="mailto:infor@factiverse.ai">info@factiverse.ai</Link>
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostPaymentPage;
