import { useAuth0 } from '@auth0/auth0-react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import MenuLogo from 'assets/img/menuLogo.png';
import YellowBorderButton from 'components/buttons/YellowBorderButton';
import YellowButton from 'components/buttons/YellowButton';
import React from 'react';
import MenuIcon from 'assets/img/icons/MenuIcon';
import { useEffect, useState } from 'react';
import { DARKER_GREY, MAIN_BOX_SHADOW } from 'utils/theme';
import ManageSubscription from './components/ManageSubscription';
import Sidebar from './components/MenuContent';
import UserMenu from './components/UserMenu';
import ArrowRightIcon from 'assets/img/icons/ArrowRightIcon';
import MenuFooter from './components/MenuFooter';
import ArrowLeftIcon from 'assets/img/icons/ArrowLeftIcon';

interface NavbarProps {
  handleNavbarToggle: (isNavbarExpanded: boolean) => void;
}

const NavBar = ({ handleNavbarToggle }: NavbarProps) => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('desktop'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarExpanded((prev) => !prev);
  };

  const toggleNavbar = () => {
    const newExpandedState = !isNavbarExpanded;
    setIsNavbarExpanded((prev) => !prev);
    handleNavbarToggle(newExpandedState);
  };

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const toggleUserMenu = () => {
    setIsUserMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isSidebarExpanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isSidebarExpanded]);

  const showAvatar = isNavbarExpanded || isSmallScreen;

  return (
    <Box
      bgcolor="white"
      display="flex"
      alignItems="center"
      boxShadow={MAIN_BOX_SHADOW}
      zIndex={1000}
      sx={{
        flexDirection: { mobile: 'row', tablet: 'row', desktop: 'column' },
        width: {
          mobile: '100%',
          tablet: '100%',
          desktop: isNavbarExpanded ? '240px' : 'inherit',
        },
        height: { mobile: '56px', tablet: '56px', desktop: '100vh' },
        borderRadius: { mobile: '50px', tablet: '50px', desktop: 0 },
        padding: { mobile: '0 24px', tablet: '0 35px', desktop: 0 },
        margin: { mobile: '16px', tablet: '24px', desktop: 0 },
        position: { mobile: 'unset', tablet: 'unset', desktop: 'fixed' },
        justifyContent: {
          mobile: 'space-between',
          tablet: 'space-between',
          desktop: 'flex-start',
        },
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          flex: { mobile: '0 0 auto', tablet: '0 0 auto', desktop: undefined },
          marginBottom: { mobile: 0, tablet: 0, desktop: '20px' },
          marginTop: { mobile: '4px', tablet: '4px', desktop: '40px' },
        }}
      >
        <img
          src={MenuLogo}
          alt="navbarLogo"
          width={isSmallScreen ? '40px' : '50px'}
          style={{
            borderRadius: '8px',
          }}
        />
      </Box>

      {isAuthenticated && showAvatar && (
        <Box
          sx={{
            gap: '16px',
            marginLeft: { mobile: 'auto', tablet: 'auto', desktop: 0 },
            marginTop: { mobile: 0, tablet: 0, desktop: '20px' },
          }}
        >
          <Avatar
            src={user?.picture}
            sx={{
              width: { desktop: '50px', tablet: '40px' },
              height: { desktop: '50px', tablet: '40px' },
              marginTop: { mobile: '24px', tablet: '24px', desktop: '20px' },
              marginRight: { mobile: '16px', tablet: '24px', desktop: 0 },
              transition: '0.3s ease',
              '&:hover': {
                boxShadow: '0 0 0 3px #6C727A30',
                cursor: 'pointer',
              },
            }}
            onClick={toggleUserMenu}
          />
          {<UserMenu isOpen={isUserMenuOpen} setIsOpen={setIsUserMenuOpen} />}
        </Box>
      )}
      {!isSmallScreen && isNavbarExpanded && (
        <>
          {isAuthenticated ? (
            <Stack
              spacing={2}
              alignItems="center"
              sx={{
                flexDirection: {
                  mobile: 'row',
                  tablet: 'row',
                  desktop: 'column',
                },

                marginTop: { mobile: 0, tablet: 0, desktop: 'auto' },
                marginBottom: { mobile: 0, tablet: 0, desktop: '80px' },
              }}
            >
              <ManageSubscription
                buttonTitle="Get Premium"
                sx={{ width: '192px' }}
              />
            </Stack>
          ) : (
            <Stack
              sx={{
                flexDirection: {
                  mobile: 'row',
                  tablet: 'row',
                  desktop: 'row',
                },
                gap: '12px',

                marginTop: { mobile: '24px', tablet: '24px', desktop: 'auto' },
                marginBottom: {
                  mobile: '24px',
                  tablet: '24px',
                  desktop: '80px',
                },
              }}
            >
              <YellowBorderButton
                sx={{
                  width: { desktop: '120px' },
                  height: { desktop: '44px' },
                  borderRadius: '200px',
                }}
                onClick={() => loginWithRedirect()}
              >
                Sign Up
              </YellowBorderButton>
              <YellowButton
                sx={{
                  width: { desktop: '88px' },
                  height: { desktop: '44px' },
                  borderRadius: '200px',
                }}
                onClick={() => loginWithRedirect()}
              >
                Log In
              </YellowButton>
            </Stack>
          )}
        </>
      )}
      {!isSmallScreen && (
        <Box display="flex">
          <Divider
            sx={{
              position: 'absolute',
              bottom: '140px',
              left: 0,
              width: '100%',
              borderColor: '#0000000D',
            }}
          />
          <IconButton
            onClick={toggleNavbar}
            sx={{
              position: 'absolute',
              bottom: '72px',
              left: isNavbarExpanded ? '172px' : '78px',
              margin: '20px',
              width: '28px',
              height: '28px',
              backgroundColor: '#F1F2FB',
              border: '1px solid #FFE275',
              '&:hover': {
                border: '1px solid #F8C408',
              },
            }}
          >
            {isNavbarExpanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </IconButton>
        </Box>
      )}
      {isNavbarExpanded && <MenuFooter isMobileScreen={isMobileScreen} />}

      {isSmallScreen && (
        <Box>
          <Button
            onClick={toggleSidebar}
            sx={{
              padding: 0,
              minWidth: 'unset',
              width: '40px',
              height: '40px',
            }}
          >
            <MenuIcon stroke={DARKER_GREY} />
          </Button>

          {isSidebarExpanded && <Sidebar toggleMenu={toggleSidebar} />}
        </Box>
      )}
    </Box>
  );
};

export default NavBar;
