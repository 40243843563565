import React, { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import YellowButton from './YellowButton';

const SignupButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  const handleSignUp = useCallback(async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/playgroundV2',
      },
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }, [loginWithRedirect]);

  return <YellowButton onClick={handleSignUp}>Sign up</YellowButton>;
};

export default SignupButton;
