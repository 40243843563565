import { AxiosResponse } from 'axios';
import { getAxiosInstance } from './apiClient';

interface RelatedClaimsResponse {
  _id: string;
  collection: string;
  model: string;
  prompt: string;
  text: string;
  sentences: Array<string>;
  full_output: string;
}

export const generateRelatedClaimsRequest = (
  logging: boolean,
  text: string,
  prompt: string
): Promise<AxiosResponse<RelatedClaimsResponse>> => {
  const api = getAxiosInstance();
  return api.post('generate', {
    lang: '',
    logging,
    text: text,
    prompt: prompt,
  });
};
