import React, { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import YellowBorderButton from './YellowBorderButton';
import { Button } from '@mui/material';

type LoginButtonProps = {
  label?: string;
  variant?: 'default' | 'stripe';
};

const LoginButton: React.FC<LoginButtonProps> = ({ label, variant }) => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = useCallback(async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/playgroundV2',
      },
    });
  }, [loginWithRedirect]);

  return (
    <>
      {variant === 'default' || variant == undefined ? (
        <YellowBorderButton onClick={handleLogin}>
          {label ? label : 'Log in'}
        </YellowBorderButton>
      ) : (
        // "stripe" variant looks like the buy button from Stripe, to make it match their design
        <Button
          sx={{
            backgroundColor: 'white',
            color: 'rgb(94, 94, 94)',
            borderRadius: '22px',
            width: '288px',
            height: '44px',
            textTransform: 'none',
            fontSize: '16px',
            fontWeight: '500px',
            fontFamily: 'inherit',
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
          onClick={handleLogin}
        >
          {label ? label : 'Log in'}
        </Button>
      )}
    </>
  );
};

export default LoginButton;
